// today/src/app/pages/event/event-list/event-list.component.ts
import { Component, OnInit } from '@angular/core';
import { MenuSubService } from 'src/app/_services/menu-sub.service';
import { EVENT_MENU_OPTIONS } from 'src/app/pages/event/event-menu/event-config';

@Component({
    selector: 'app-event-list',
    templateUrl: './event-list.component.html',
    styleUrls: ['./event-list.component.css']
})
export class EventListComponent implements OnInit {

    constructor(
        private menuSubService: MenuSubService
    ) { }

    ngOnInit(): void {
        this.menuSubService.updateSubMenuOptions(EVENT_MENU_OPTIONS);
    }
}
