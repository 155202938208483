import { Component, OnInit } from '@angular/core';
import { MenuSubService } from 'src/app/_services/menu-sub.service';
import { ORGANIZATION_MENU_OPTIONS } from '../organization-menu/organization-config';

@Component({
  selector: 'app-organization-list',
  templateUrl: './organization-list.component.html',
  styleUrl: './organization-list.component.css'
})
export class OrganizationListComponent {
    constructor(
        private menuSubService: MenuSubService
    ) { }

    ngOnInit(): void {
        this.menuSubService.updateSubMenuOptions(ORGANIZATION_MENU_OPTIONS);
    }

}
