// src/app/_services/auth/auth.service.ts

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, Observable } from 'rxjs';
import { getAuthConfig, googleAuthConfig } from './auth.config';
import { environment } from 'src/environments/environment.prod';
import { AuthTokenResponse } from 'src/app/_interface/auth_token';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  private userDetailsSubject = new BehaviorSubject<any>(null);
  private userFirstName = new BehaviorSubject<string>(sessionStorage.getItem('firstname') || '');
  private userLastName = new BehaviorSubject<string>(sessionStorage.getItem('lastname') || '');
  private userFullName = new BehaviorSubject<string>(sessionStorage.getItem('fullname') || '');
  private userPicture = new BehaviorSubject<string>(sessionStorage.getItem('picture') || '');
  private userGroups = new BehaviorSubject<string[]>([]);
  public userIsStaff = new BehaviorSubject<boolean>(false);

  private accessTokenKey = 'access_token';
  private refreshTokenKey = 'refresh_token';

  constructor(
    private oauthService: OAuthService,
    private http: HttpClient
  ) {
    if (environment.useFakeAuth) {
      this.fakeLogin();
    } else {
      this.checkIfUserIsLoggedIn();
    }
  }

  private checkIfUserIsLoggedIn() {
    const accessToken = sessionStorage.getItem(this.accessTokenKey);
    if (accessToken) {
      console.log('User is already logged in.');
      this.isAuthenticatedSubject.next(true);
    } else {
      console.log('User is not logged in.');
    }
  }

  loginWithGoogle() {
    // Redirect to Django's Allauth Google login endpoint
    window.location.href = 'https://patton.today/api/accounts/google/login/';
  }

  loginWithFacebook() {
    const config = getAuthConfig('facebook');
    const clientId = config.clientId || 'defaultClientId';
    const redirectUri = config.redirectUri || 'defaultRedirectUri';

    const url = `https://www.facebook.com/dialog/oauth?client_id=${encodeURIComponent(clientId)}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=email,public_profile&response_type=token`;
    window.location.href = url;
  }

  private configureOAuth(provider: 'google' | 'facebook') {
    const config = getAuthConfig(provider);
    this.oauthService.configure(config);

    if (provider === 'google') {
      this.oauthService.loadDiscoveryDocument();
    }
  }

  handleSuccessfulLogin() {
    const token = sessionStorage.getItem('access_token');
    const jdata = sessionStorage.getItem('id_token_claims_obj');
    if (jdata && token) {
      const uData = JSON.parse(jdata);
      const { email, given_name, family_name, picture, locale } = uData;

      this.sendToken(token, email, given_name, family_name, picture, locale).subscribe({
        next: (response: AuthTokenResponse) => {
          console.log('Token sent successfully', response);
          if (response.access && response.refresh) {
            this.storeTokens(response.access, response.refresh);
            this.isAuthenticatedSubject.next(true);

            // Update user groups, and set userIsStaff if "staff" is present // <-- ADDED
            if (response.user && response.user.groups) {
              this.userGroups.next(response.user.groups);

              const isStaff = response.user.groups.includes('staff'); // <-- ADDED
              this.setUserIsStaff(isStaff);                           // <-- ADDED
            }
          } else {
            console.error('Missing access or refresh token in the response');
          }
        },
        error: (error) => console.error('Error sending token', error)
      });
    } else {
      console.log('No user data in id_token_claims_obj');
    }
  }

  private storeTokens(access: string, refresh: string) {
    console.log('Storing tokens:', access, refresh);
    sessionStorage.setItem(this.accessTokenKey, access);
    sessionStorage.setItem(this.refreshTokenKey, refresh);

    // Check if the values were successfully stored
    const storedAccessToken = sessionStorage.getItem(this.accessTokenKey);
    const storedRefreshToken = sessionStorage.getItem(this.refreshTokenKey);
    if (storedAccessToken && storedRefreshToken) {
      console.log('Tokens stored successfully');
    } else {
      console.error('Failed to store tokens');
    }
  }

  getAccessToken(): string | null {
    return sessionStorage.getItem(this.accessTokenKey);
  }

  getJWTForLoggedInUser(): Observable<any> {
    const url = 'https://patton.today/api/auth/get_jwt/';
    return this.http.get(url, { withCredentials: true });
  }

  getRefreshToken(): string | null {
    return sessionStorage.getItem(this.refreshTokenKey);
  }

  getUserGroups(): Observable<string[]> {
    return this.userGroups.asObservable();
  }

  getUserIsStaff(): Observable<boolean> {
    return this.userIsStaff.asObservable();
  }

  isUserInGroup(groupName: string): boolean {
    const groups = this.userGroups.getValue();
    return groups.includes(groupName);
  }

  sendTokenToBackend(idToken: string): Observable<any> {
    const url = 'https://patton.today/api/auth/verify-google-token/';
    return this.http.post(url, { id_token: idToken });
  }

  refreshToken(): Observable<any> {
    const refreshToken = this.getRefreshToken();
    if (refreshToken) {
      return this.http.post(`${environment.apiBaseUrl}/api/token/refresh/`, {
        refresh: refreshToken
      });
    } else {
      throw new Error('No refresh token available');
    }
  }

  logout() {
    this.oauthService.logOut();
    sessionStorage.clear();
    this.isAuthenticatedSubject.next(false);
    this.userDetailsSubject.next(null);
    sessionStorage.removeItem('currentUser');
    this.resetUserDetails();
  }

  private resetUserDetails() {
    this.setUserFirstName('');
    this.setUserFullName('');
    this.setUserLastName('');
    this.setUserPicture('');
  }

  sendToken(token: string, email: string, name_first: string, name_last: string, picture: string, locale: string): Observable<AuthTokenResponse> {
    const url = `${environment.apiBaseUrl}/api/auth/google/login/`;
    return this.http.post<AuthTokenResponse>(url, { token, email, name_first, name_last, picture, locale });
  }

  isAuthenticated(): Observable<boolean> {
    return this.isAuthenticatedSubject.asObservable();
  }

  userDetails(): Observable<any> {
    return this.userDetailsSubject.asObservable();
  }

  private storeUserDetails() {
    const jdata = sessionStorage.getItem('id_token_claims_obj');
    if (jdata) {
      const uData = JSON.parse(jdata);
      this.userDetailsSubject.next(uData);
    } else {
      console.log('No user data in id_token_claims_obj');
    }
  }

  // Getter functions for user information

  getUserFirstName(): Observable<string> {
    return this.userFirstName.asObservable();
  }

  getUserLastName(): Observable<string> {
    return this.userLastName.asObservable();
  }

  getUserFullName(): Observable<string> {
    return this.userFullName.asObservable();
  }

  getUserPicture(): Observable<string> {
    return this.userPicture.asObservable();
  }

  setUserFirstName(firstName: string) {
    sessionStorage.setItem('firstname', firstName);
    this.userFirstName.next(firstName);
  }

  setUserLastName(lastName: string) {
    sessionStorage.setItem('lastname', lastName);
    this.userLastName.next(lastName);
  }

  setUserFullName(fullName: string) {
    sessionStorage.setItem('fullname', fullName);
    this.userFullName.next(fullName);
  }

  setUserIsStaff(isStaff: boolean): void {
    this.userIsStaff.next(isStaff);
  }

  setUserPicture(picture: string) {
    sessionStorage.setItem('picture', picture);
    this.userPicture.next(picture);
  }

  // Fake login method for development
  private fakeLogin() {
    const mockUser = {
      email: 'mockuser@example.com',
      given_name: 'Mock',
      family_name: 'User',
      picture: 'https://via.placeholder.com/150',
      locale: 'en'
    };

    sessionStorage.setItem('id_token_claims_obj', JSON.stringify(mockUser));
    sessionStorage.setItem(this.accessTokenKey, 'mock-access-token');
    sessionStorage.setItem(this.refreshTokenKey, 'mock-refresh-token');
    this.isAuthenticatedSubject.next(true);
    this.handleSuccessfulLogin();
  }
}
