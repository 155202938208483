// src/app/_services/auth/auth.config.ts
import { AuthConfig } from 'angular-oauth2-oidc';

// Default Google Configuration
export const googleAuthConfig: AuthConfig = {
    issuer: 'https://accounts.google.com',
    redirectUri: window.location.origin + '/home',
    clientId: '950930231611-9d8sl6tsfc3kgeigi5f5tdv62bsijih9.apps.googleusercontent.com',
    scope: 'openid profile email',
    strictDiscoveryDocumentValidation: false,
};

// Facebook Configuration
export const facebookAuthConfig: AuthConfig = {
    issuer: 'https://www.facebook.com',
    redirectUri: window.location.origin + '/home',
    clientId: '315953358188564',
    scope: 'email public_profile',
    responseType: 'token',
    strictDiscoveryDocumentValidation: false,
};

// Function to get the configuration based on the provider
export function getAuthConfig(provider: 'google' | 'facebook'): AuthConfig {
    if (provider === 'facebook') {
        return facebookAuthConfig;
    }
    return googleAuthConfig;
}
