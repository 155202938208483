// today/src/app/pages/event/event.module.ts
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventAddComponent } from 'src/app/pages/event/event-add/event-add.component';
import { EventListComponent } from 'src/app/pages/event/event-list/event-list.component';
import { EventsRoutingModule } from './event-routing.module';

@NgModule({
    declarations: [
        EventAddComponent,
        EventListComponent
    ],
    imports: [
        CommonModule,
        EventsRoutingModule
    ],
    exports: [
        EventAddComponent,
        EventListComponent
    ]
})
export class EventModule { }
