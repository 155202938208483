// nav-sub.component.ts
import { Component, OnInit } from '@angular/core';
import { MenuSubService } from 'src/app/_services/menu-sub.service';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-nav-sub',
  templateUrl: './nav-sub.component.html',
  styleUrls: ['./nav-sub.component.css']
})
export class NavSubComponent implements OnInit {
  subMenuOptions: any[] = [];
  isStaff = false;

  constructor(
    private menuSubService: MenuSubService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    // Combine both streams: The sub-menu options and the userIsStaff observable
    combineLatest([
      this.menuSubService.subMenuOptions$,
      this.authService.getUserIsStaff()
    ]).subscribe(([options, isStaff]) => {
      this.isStaff = isStaff;

      // Filter out items that require staff if user isn't staff
      this.subMenuOptions = options.filter(item => {
        // If requiresStaff = true but isStaff is false, skip it
        if (item.requiresStaff && !isStaff) {
          return false;
        }
        return true;
      });
    });
  }
}
