import { Component } from '@angular/core';

@Component({
  selector: 'app-organization-edit',
  templateUrl: './organization-edit.component.html',
  styleUrl: './organization-edit.component.css'
})
export class OrganizationEditComponent {

}
