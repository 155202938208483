// src/app/app-routing.module.ts
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';

export const routes: Routes = [
    {
        path: 'about',
        loadChildren: () => import('./pages/about/about.module').then(m => m.AboutModule)
    },
    {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
    },
    {
        path: 'arts',
        loadChildren: () => import('./pages/arts/art/art.module').then(m => m.ArtModule)
    },
    {
        path: 'event',
        loadChildren: () => import('./pages/event/event.module').then(m => m.EventModule)
    },
    {
        path: 'bigbrick',
        loadChildren: () => import('./pages/bigbrick/bigbrick.module').then(m => m.BigbrickModule)
    },
    {
        path: 'deals',
        loadChildren: () => import('./pages/deals/deals.module').then(m => m.DealsModule)
    },
    {
        path: 'directory',
        loadChildren: () => import('./pages/organization/organization.module').then(m => m.OrganizationModule)
    },
    {
        path: 'discover',
        loadChildren: () => import('./pages/discover/discover.module').then(m => m.DiscoverModule)
    },
    {
        path: 'events',
        loadChildren: () => import('./pages/event/event.module').then(m => m.EventModule)
    },
    {
        path: 'food',
        loadChildren: () => import('./pages/food/food.module').then(m => m.FoodModule)
    },
    {
        path: 'grow',
        loadChildren: () => import('./pages/grow/grow.module').then(m => m.GrowModule)
    },
    {
        path: 'jobs',
        loadChildren: () => import('./pages/jobs/jobs.module').then(m => m.JobsModule)
    },
    {
        path: 'login',
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
    },
    {
        path: 'market',
        loadChildren: () => import('./pages/market/market.module').then(m => m.MarketModule)
    },
    {
        path: 'news',
        loadChildren: () => import('./pages/news/news.module').then(m => m.NewsModule)
    },
    {
        path: 'register',
        loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule)
    },
    { path: 'home', component: HomeComponent },
    { path: '', redirectTo: '/home', pathMatch: 'full' } // Default route
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
