import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrganizationAddComponent } from 'src/app/pages/organization/organization-add/organization-add.component';
import { OrganizationEditComponent } from 'src/app/pages/organization/organization-edit/organization-edit.component';
import { OrganizationListComponent } from 'src/app/pages/organization/organization-list/organization-list.component';
import { OrganizationMenuComponent } from 'src/app/pages/organization/organization-menu/organization-menu.component';
import { OrganizationRoutingModule } from 'src/app/pages/organization/organization-routing.module';

@NgModule({
    declarations: [
        OrganizationAddComponent,
        OrganizationEditComponent,
        OrganizationListComponent,
        OrganizationMenuComponent,
    ],
    imports: [
        CommonModule,
        OrganizationRoutingModule
    ],
    exports: [
        OrganizationAddComponent,
        OrganizationEditComponent,
        OrganizationListComponent,
        OrganizationMenuComponent,
    ]
})
export class OrganizationModule { }
