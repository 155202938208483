import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {
  constructor(private authService: AuthService, private router: Router) {}
  

ngOnInit(): void {
    const hash = window.location.hash.substring(1);
    const params = new URLSearchParams(hash);

    const idToken = params.get('id_token');
    const accessToken = params.get('access_token');

    if (idToken && accessToken) {
        this.authService.sendTokenToBackend(idToken).subscribe({
            next: (response) => {
                console.log('Backend response:', response);

                // --- Store tokens in sessionStorage
                sessionStorage.setItem('access_token', response.access);
                sessionStorage.setItem('refresh_token', response.refresh);

                // --- Extract user
                const user = response.user;
                
                // 1) Store the is_staff in an observable
                this.authService.setUserIsStaff(user.is_staff);

                // 2) Remove is_staff from the object
                delete user.is_staff;

                // 3) Now store the user object (minus is_staff) in session
                sessionStorage.setItem('user', JSON.stringify(user));

                // The rest of your user profile data (first/last name, picture, etc.)
                this.authService.setUserFirstName(user['first_name']);
                this.authService.setUserFullName(`${user['first_name']} ${user['last_name']}`);
                this.authService.setUserLastName(user['last_name']);
                this.authService.setUserPicture(user['picture']);

                // Update authentication state in AuthService
                this.authService.isAuthenticatedSubject.next(true);

                // Redirect to dashboard
                this.router.navigate(['/home']);
            },
            error: (err) => {
                console.error('Error verifying token with backend:', err);
            },
        });
    } else {
        console.error('Tokens not found in URL fragment.');
    }
    }
}
