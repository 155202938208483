// today/src/app/_services/menu-sub.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuSubService {
  private subMenuOptionsSource = new BehaviorSubject<any[]>([]);
  subMenuOptions$ = this.subMenuOptionsSource.asObservable();

  constructor() { }

  updateSubMenuOptions(options: any[]) {
    this.subMenuOptionsSource.next(options);
  }
}
