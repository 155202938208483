// today/src/app/pages/organization/organization-menu/organization-menu.component.ts
import { Component } from '@angular/core';

@Component({
  selector: 'app-organization-menu',
  templateUrl: './organization-menu.component.html',
  styleUrl: './organization-menu.component.css'
})
export class OrganizationMenuComponent {

}
