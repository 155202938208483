import { Component } from '@angular/core';

@Component({
  selector: 'app-organization-add',
  templateUrl: './organization-add.component.html',
  styleUrl: './organization-add.component.css'
})
export class OrganizationAddComponent {

}
